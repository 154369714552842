<template>
  <view-item-setting>
    <template #operation>
      <en-button type="primary" :disabled="!store.accessRightsHash.VEHICLE_INSPECTION_EDIT" @click="operation.add.click">添加检测方案</en-button>
    </template>

    <tabs-maintain
      v-model="tabs.active"
      :options="[
        { label: '未启用', value: 'N' },
        { label: '已启用', value: 'Y' }
      ]"
      :method="table.get"
      :config="{ order: 'before' }"
    ></tabs-maintain>

    <flex-box>
      <template #default="{ height }">
        <table-dynamic
          :height="height"
          code="VHISTPNFD"
          :data="table.data"
          :loading="table.loading"
          :paging="table.paging"
          :method="table.get"
          pagination
          :config="table.config"
        >
          <template #OPERATION="{ row }: { row: EnocloudCommonDefinitions['VehicleInspectionCategoryDto'] }">
            <button-delete :method="table.operation.delete.click" :params="row">删除</button-delete>
            <button-ajax link :method="table.operation.enable.click" :params="row" v-if="row.enable?.code === 'Y'">禁用 </button-ajax>
            <button-ajax link :method="table.operation.enable.click" :params="row" v-if="row.enable?.code === 'N'">启用</button-ajax>
          </template>
          <template #STATUS="{ row }: { row: EnocloudCommonDefinitions['VehicleInspectionCategoryDto'] }">
            <en-tag :type="row.enable?.code === 'N' ? 'info' : ''">{{ row.enable?.code === 'Y' ? '已启用' : '未启用' }}</en-tag>
          </template>
          <template #SERIAL_NO="{ row }: { row: EnocloudCommonDefinitions['VehicleInspectionCategoryDto'] }">
            <en-button type="primary" link @click="table.operation.serialNo.click(row)">{{ row.serialNo }}</en-button>
          </template>
        </table-dynamic>
      </template>
    </flex-box>
  </view-item-setting>

  <en-drawer v-model="detail.visible" :title="detail.form.data.id ? '编辑' : '添加' + '检测方案'" direction="btt" size="80%">
    <en-form
      :model="detail.form.data"
      :rules="detail.form.rules"
      :loading="detail.form.loading"
      :ref="setRef('detailForm')"
      class="grid grid-cols-5 gap-6"
    >
      <en-form-item label="方案编号">
        <en-input :model-value="detail.form.data.serialNo" disabled></en-input>
      </en-form-item>
      <en-form-item label="检测方案名称" prop="name">
        <en-input v-model="detail.form.data.name" :disabled="detail.form.disabled"></en-input>
      </en-form-item>
    </en-form>

    <div class="py-2">
      <span>注意事项：1、车况报告中的检测分类按所添加的先后顺序展示。2、每个监测方案中同样的检测分类只能添加一次。</span>
    </div>

    <flex-box>
      <template #default="{ height }">
        <en-table :data="detail.form.inspectionPlanItems.data" :height="height" :loading="detail.form.loading">
          <en-table-column label="序号" type="index" width="100"></en-table-column>
          <en-table-column label="检测分类">
            <template #default="{ row }: { row: InspectionPlanItems }">
              <select-maintain
                v-model="row.category"
                :ajax="{
                  action: 'GET /enocloud/common/vehicle/inspection/category',
                  params: (params, value) => (params.payload = { categoryName: value, enableCode: 'Y' })
                }"
                :props="{
                  label: 'name',
                  value: '',
                  disabled: (option: EnocloudCommonDefinitions['VehicleInspectionCategoryDto']) => {
                    return detail.form.data.inspectionPlanItems?.map(item => item.category?.id).includes(option.id)
                  }
                }"
                value-key="id"
                remote
                :disabled="Boolean(row.category?.id) || detail.form.disabled"
              ></select-maintain>
            </template>
          </en-table-column>
          <en-table-column label="检测项目" prop="enable.message">
            <template #default="{ row }: { row: InspectionPlanItems }">
              <select-maintain
                v-model="row.items"
                :key="row.category?.id"
                :ajax="{
                  action: 'GET /enocloud/common/vehicle/inspection/category/:categoryId',
                  params: (params) => (params.paths = [row.category?.id]),
                  convert: (data) => {
                    return data?.[0].inspectionItems?.filter((item) => item.enable?.value) ?? []
                  }
                }"
                :props="{ label: 'name', value: '' }"
                value-key="id"
                multiple
                lazy
                collapse-tags-panel
                :disabled="!row.category?.id || detail.form.disabled"
                debug
              ></select-maintain
            ></template>
          </en-table-column>
          <en-table-column label="操作">
            <template #default="{ row }: { row: InspectionPlanItems }">
              <en-button type="primary" text @click="detail.form.inspectionPlanItems.delete.click(row)" :disabled="detail.form.disabled">
                删除
              </en-button>
            </template>
          </en-table-column>
        </en-table>
      </template>
    </flex-box>

    <div>
      <span v-if="!detail.form.disabled" @click="detail.form.inspectionPlanItems.add.click" class="cursor-pointer text-sm text-primary">
        添加检测项目
      </span>
    </div>

    <template #footer>
      <en-button @click="detail.footer.cancel.click">取消</en-button>
      <button-ajax :method="detail.footer.confirm.click" :disabled="detail.form.disabled"> 确定 </button-ajax>
    </template>
  </en-drawer>
</template>

<script lang="ts">
import { find, findIndex, filter, uniqBy } from 'lodash-es'

interface InspectionPlanItems {
  category: EnocloudCommonDefinitions['VehicleInspectionCategoryDto'] | undefined
  items: EnocloudCommonDefinitions['VehicleInspectionItemDto'][]
}

export default factory({
  config: {
    children: {
      operation: {
        add: {
          click() {
            this.detail.form.init()
            this.detail.visible = true
          }
        }
      },
      tabs: {
        active: ''
      },
      table: {
        ajax: {
          get: {
            action: 'GET /enocloud/common/vehicle/inspection/plan',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = { enableCode: this.tabs.active }
            }
          },
          delete: {
            action: 'DELETE /enocloud/common/vehicle/inspection/plan/:planId',
            loading: true
          },
          enable: {
            action: 'PUT /enocloud/common/vehicle/inspection/plan/enable',
            loading: true,
            convert(data) {
              data.enable.code = data.enable.code === 'Y' ? 'N' : 'Y'
              return data
            }
          }
        },
        children: {
          operation: {
            delete: {
              async click(row: EnocloudCommonDefinitions['VehicleInspectionPlanDto']) {
                await this.table.delete({ paths: [row.id] })
                return this.table.get()
              }
            },
            enable: {
              async click(row: EnocloudCommonDefinitions['VehicleInspectionPlanDto']) {
                await this.table.enable({
                  payload: Object.assign({}, row, { enable: { code: row.enable?.value ? 'N' : 'Y' } })
                })
                return this.table.get()
              }
            },
            serialNo: {
              async click(row: EnocloudCommonDefinitions['VehicleInspectionPlanDto']) {
                this.detail.form.init()
                this.detail.form.data.id = row.id
                await this.detail.form.get()
                this.detail.form.inspectionPlanItems.data = uniqBy(this.detail.form.data.inspectionPlanItems, 'category.id').map((item) => {
                  return {
                    category: item.category,
                    items: filter(this.detail.form.data.inspectionPlanItems, ['category.id', item.category?.id])
                  }
                })
                this.detail.visible = true
              }
            }
          }
        },
        config: {
          PREPARED_DATETIME: {
            header: {
              filter: {
                type: 'date',
                field: ['startDate', 'endDate'],
                props: { type: 'daterange' }
              }
            }
          },
          NAME: { header: { filter: { type: 'text', field: 'planName' } } }
        }
      },
      detail: {
        visible: false,
        children: {
          form: {
            is: 'form',
            ajax: {
              get: {
                action: 'GET /enocloud/common/vehicle/inspection/plan/:planId',
                data: 'object',
                loading: true,
                params(params) {
                  params.paths = [this.detail.form.data.id]
                }
              },
              submit: {
                action: 'POST /enocloud/common/vehicle/inspection/plan',
                loading: true,
                validate: true,
                params(params) {
                  params.payload = this.detail.form.data
                }
              },
              update: {
                action: 'PUT /enocloud/common/vehicle/inspection/plan',
                loading: true,
                validate: true,
                params(params) {
                  params.payload = this.detail.form.data
                }
              }
            },
            rules: {
              name: [{ required: true, message: '请输入检测方案名称' }],
              'category.id': [{ required: true, message: '请选择检测项目类别' }],
              excellentConditionDescription: [{ required: true, message: '请输入文本' }],
              fairConditionDescription: [{ required: true, message: '请输入文本' }],
              poorConditionDescription: [{ required: true, message: '请输入文本' }]
            },
            computed: {
              disabled() {
                return this.detail.form.data.enable?.value || !this.store.accessRightsHash.VEHICLE_INSPECTION_EDIT
              }
            },
            children: {
              inspectionPlanItems: {
                data: [] as InspectionPlanItems[],
                add: {
                  click() {
                    this.detail.form.inspectionPlanItems.data.unshift({ category: {}, items: [] })
                  }
                },
                delete: {
                  click(row: EnocloudCommonDefinitions['VehicleInspectionItemDto']) {
                    const index = findIndex(this.detail.form.inspectionPlanItems.data, ['category.id', row.category?.id])
                    if (index > -1) {
                      this.detail.form.inspectionPlanItems.data.splice(index, 1)
                    }
                  }
                }
              }
            }
          },
          footer: {
            cancel: {
              click() {
                this.detail.visible = false
              }
            },
            confirm: {
              async click() {
                this.detail.form.data.inspectionPlanItems = this.detail.form.inspectionPlanItems.data.reduce((inspectionPlanItems, item) => {
                  if (item.category?.id) {
                    for (const inner of item.items) {
                      inspectionPlanItems.push({ ...inner, category: item.category })
                    }
                  }
                  return inspectionPlanItems
                }, [] as EnocloudCommonDefinitions['VehicleInspectionItemDto'][])
                await this.detail.form[this.detail.form.data.id ? 'update' : 'submit']()
                return this.table.get().then(() => (this.detail.visible = false))
              }
            }
          }
        }
      }
    }
  },

  mounted() {
    this.table.get()
  }
})
</script>
